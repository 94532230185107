.residents-container {
  width: 100%;
  min-height: 70vh;
  .resident-overview-data {
    h1,
    h2,
    h3 {
      margin-top: unset;
      margin-bottom: unset;
    }
    padding: 24px 32px;
    border-bottom: 1px solid #f6f5fa;
    button {
      border: none;
      background: none;
      display: block;
      padding: 8px 16px;
      border-radius: 3px;
      background-color: #5341c4;
      font-weight: 600;
      color: #fff;
      font-size: 12px;
      transition: all 0.3s;
      &:hover,
      &:active,
      &:focus,
      &:visited {
        background-color: #6c5bdd !important;
        border: none;
      }
      &.button-secondary {
        background-color: #f6f5fa;
        color: #505163;
        &:hover {
          background-color: #dddce2 !important;
        }
      }
    }
    .menu-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
  }
  .residents-table {
    width: 100%;
    padding: 2%;
    @media (min-width: 1200px) {
      padding: 3%;
      width: 80%;
      max-width: 900px;
    }
    table {
      font-size: 14px;
      font-weight: 500;
      thead {
        color: #000000;
        th {
          padding: 16px 0;
        }
      }
      tbody {
        color: #484848;
        tr {
          border-bottom: 1px solid #f3f3f3;
          &:hover {
            background-color: #f8f7ff;
            cursor: pointer;
          }
        }
        td {
          padding: 22px 0;
        }
      }
    }
  }
  .offcanvas.offcanvas-right {
    right: 0 !important;
  }
}

.status {
  span {
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 16px;
    border-radius: 3px;
    &.active {
      background-color: #a9dfbf;
    }
    &.disabled {
      background-color: #ffe2e1;
    }
  }
}
.side-panel {
  &-body {
    .tabs {
      display: flex;
      align-items: center;
      gap: 24px;
      border-bottom: 1px solid #e1d8f2;
      margin-bottom: 32px;
      .tab {
        width: 72px;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        color: #928e94;
        transition: all 0.5s ease-in-out;
        &.active {
          color: #5341c5;
          border-bottom: 2px solid #5341c5;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .user-info {
      padding: 0 12px;
      width: 98%;
      margin-bottom: 32px;
      border-bottom: 1px solid #e1d8f2;
      .name {
        font-size: 22px;
        font-weight: 600;
        color: #111111;
        margin-bottom: 6px;
      }
      .access {
        color: #555063;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        span.status {
          display: inline-block;
          margin-left: 14px;
          position: relative;
          bottom: 2px;
        }
      }
      .address {
        color: #6a686f;
        font-size: 15px;
        font-weight: 400;
        margin-bottom: 32px;
        line-height: 24px;
      }
    }
    .others {
      padding: 0 12px;
      margin-bottom: 32px;
      .sec-title {
        color: #111111;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .other {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          color: #6d686f;
          font-size: 15px;
          font-weight: 500;
        }
        img {
          width: 14px;
          height: 14px;
          margin-right: 10px;
        }
      }
    }
    .actions {
      padding: 0 12px;
      display: flex;
      align-items: center;
      margin-bottom: 32px;
      gap: 24px;
      button {
        padding: 8px 16px;
        border-radius: 3px;
        font-weight: 600;
        background-color: #5341c5;
        border: 2px solid #5341c4;
        color: #ffffff;
        transition: all 0.3s ease-in-out;
        &:hover {
          background-color: #6551e8;
          cursor: pointer;
        }
        &.b {
          color: #5341c4;
          background-color: transparent;
          border: 2px solid #5341c4;
        }
      }
    }
    button.delete {
      padding: 8px 16px;
      color: #f04242;
      font-weight: 600;
      font-size: 14px;
    }
    .visitorrs {
      .pass-details {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1.5px solid #f2f2f2;
        width: 98%;
        @media (min-width: 1200px) {
          width: 90%;
        }
        img {
          &.item-icon {
            width: 60px;
            height: 60px;
            margin-right: 10px;
          }
          &.data-icon {
            height: 16px;
          }
        }
        .pass-type {
          color: #555063;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
        }
        .user {
          color: #6d686f;
          font-size: 16px;
          line-height: 32px;
          font-weight: 500;
        }
        .address {
          margin-bottom: 4px;
          span {
            color: #928e94;
            line-height: 28px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
          }
          img {
            margin-right: 4px;
          }
        }
        .time-access {
          .time {
            margin-right: 8px;
          }
          img.data-icon {
            margin-right: 4px;
          }
          .status {
            padding: 4px 10px;
            color: #505963;
            font-size: 10px;
            font-weight: 600;
            border-radius: 2px;
            text-transform: uppercase;
            line-height: 12px;
            &.granted {
              background-color: #a9dfbf;
            }
            &.declined {
              background-color: #ffe2e1;
            }
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(220px);
  }
  100% {
    transform: translateX(0);
  }
}
