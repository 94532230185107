.custom-login {
  background-color: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem !important;
  .view-title {
    color: #000000;
    font-size: 24px;
    font-weight: 600;
  }
  .google-btn {
    background: none;
    border: none;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #56575829;
    padding: 14px 0;
    margin-bottom: 24px;
    span {
      font-weight: 600;
      font-size: 16px;
      color: #181819e5;
    }
    img {
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }
  .divider-or {
    position: relative;
    width: 100%;
    margin: 24px 0;
    width: 100%;
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #e6e7e9;
    }
    span {
      position: absolute;
      left: calc(50% - 28px);
      bottom: -18px;
      width: 60px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      color: #1818196b;
    }
  }
  form.form {
    input.form-control {
      border-radius: 0.2rem;
      height: 48px;
    }
    label {
      font-size: 14px;
      font-weight: 500;
    }
    .show-password {
      background: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      border: none;
      color: #5341c4;
    }
    .btn-primary {
      border-radius: 0.2rem;
    }
  }
}
