h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}
.side-panel {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  z-index: 0;
  animation: slideIn 0.3s forwards;
  -webkit-animation: slideIn 0.5s forwards;
  &-overlay {
    width: 100%;
    height: 100%;
    // background-color: rgba(116, 116, 116, 0.1);
    background-color: #cccccc01;
    // background-color: transparent;
  }
  &-body {
    z-index: 10;
    width: 380px;
    height: 100%;
    background-color: #f7f5fa;
    position: fixed;
    right: 0;
    padding: 16px;
    overflow-y: auto;
    span,
    button {
      display: block;
    }
    button {
      border: none;
      background: none;
    }
    &-header {
      display: flex;
      align-items: center;
      margin-top: 18px;
      margin-bottom: 40px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
        margin-right: 14px;
      }
      span {
        color: #555063;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.modal-content {
  .header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c6d1e0;
    padding-bottom: 32px;
    height: unset !important;
    .modal-icon {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }
  .content {
    padding: 24px;
    width: 98%;
    @media (min-width: 1200px) {
      width: 90%;
    }
    &.manage-role {
      width: 100%;
      @media (min-width: 1200px) {
        width: 98%;
      }
    }
    .warning,
    span {
      display: block;
      font-size: 15px;
      font-weight: 450;
      margin-bottom: 16px;
      color: #6a686f;
      line-height: 24px;
      &.name {
        font-size: 22px;
        font-weight: 600;
        color: #111111;
      }
    }
    .warning {
      margin-bottom: 24px;
    }
    .form-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
    .requirements {
      width: 88%;
      h5 {
        font-size: 20px;
        font-weight: 500;
        color: #555063;
        padding-bottom: 24px;
      }
      ul {
        margin-block-start: 0 !important;
        padding-inline-start: 1em;
        li {
          color: #6d686f;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 24px;
        }
      }
      .upload {
        img {
          position: relative;
          bottom: 2px;
          margin-right: 12px;
        }
        label {
          border: none;
          background: none;
          font-size: 16px;
          color: #7061cf;
          font-weight: 500;
          cursor: pointer;
        }
        input {
          visibility: hidden;
          display: none;
        }
      }
    }
    .codes {
      width: 90%;
      span {
        margin-bottom: 0;
      }
      .info {
        margin: 32px 0;
        .code {
          color: #928e94;
          font-size: 64px;
          font-weight: 700;
        }
      }
      button {
        display: inline-flex;
        border: none;
        background: none;
        font-size: 15px;
        color: #6d686f;
        padding: 14px 24px;
        padding-left: 4px;
        margin-bottom: 10px;
        &.timeline {
          border-radius: 3px;
          border: 1px solid #dddddd;
        }
        img {
          margin-right: 10px;
        }
      }
    }
    .field {
      position: relative;
      margin-bottom: 40px;
      img {
        position: absolute;
        right: 10px;
        top: 14px;
        cursor: pointer;
      }
    }
    p.instruction {
      font-size: 16px;
      line-height: 28px;
    }
    .change-role {
      &-form {
        margin-bottom: 32px;
        select {
          width: 100%;
          border: none;
          padding: 10px;
          padding-left: 0;
          font-size: 16px;
          border-bottom: 1px solid #adb8c7;
          color: #111111;
          outline: none;
        }
      }
      ul {
        margin-block-start: 0 !important;
        padding-inline-start: 1em;
        li {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 14px;
          span {
            display: block;
            &.key {
              width: 33%;
              font-weight: 400;
              color: #333333;
            }
            &.description {
              width: 90%;
              color: #7C7C7C;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  .footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    border-top: 1px solid #c6d1e0;
    button {
      background: none;
      border: none;
      padding: 10px 16px;
      border-radius: 3px;
      font-weight: 600;
      &.cancel {
        background-color: #f2f2f2;
        color: #555063;
      }
      &.danger {
        background-color: #f04242;
        color: #ffffff;
      }
      &.primary {
        color: #ffffff;
        background-color: #5341c5;
      }
      &.plain {
        color: #555063;
        background-color: #ffffff;
      }
    }
  }
  .modal-input {
    border: none;
    padding: 10px;
    padding-left: 0;
    border-bottom: 1px solid #adb8c7;
    width: 100%;
    color: #111111;
    outline: none;
    transition: all 0.2s ease-in;
    font-size: 16px;
    &::placeholder {
      color: #505963;
    }
    &:active,
    &:after,
    &:focus,
    &:visited {
      outline: none;
      border-bottom: 1px solid #5341c5;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(220px);
  }
  100% {
    transform: translateX(0);
  }
}
