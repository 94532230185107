.estate-dashboard {
  background: #f7f5fa;
  min-height: 90vh;
  .estate-overview {
    background-color: #ffffff;
    padding: 24px 4%;
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    margin-bottom: 1rem;
    @media (min-width: 1200px) {
      padding-right: 20%;
    }
    &-data {
      span {
        display: block;
        line-height: 32px;
        font-size: 20px;
        font-weight: 600;
      }
      button {
        background: none;
        border: none;
        background-color: #f2f2f2;
        width: 100%;
        border-radius: 4px;
        color: #555063;
        font-size: 12px;
        font-weight: 600;
        padding: 8px 12px;
        transition: all 0.3s;
        &:hover {
          background-color: #e6e6e6;
        }
      }
      .labell {
        color: #bab9bb;
        &.expiry {
          color: #eb5757;
          font-size: 14px;
        }
      }
      .value {
        color: #363439;
        .date {
          display: inline;
          color: #928e94;
          font-weight: 500;
        }
      }
    }
  }
  .passess {
    width: 100%;
    padding: 3%;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (min-width: 1200px) {
      width: 90%;
      max-width: 1100px;
      padding: 5%;
      padding-top: 3%;
    }
    .left-col {
      @media (min-width: 1024px) {
        position: relative;
        left: 1rem;
      }
      @media (min-width: 1200px) {
        left: 3.5rem;
      }
    }
    .section-title {
      color: #555063;
      font-size: 16px;
      font-weight: 450;
      padding-bottom: 16px;
    }
    .verify-code {
      margin-bottom: 32px;
      input {
        border: none;
        border-bottom: 1.5px solid #adb8c7;
        width: calc(100% - 120px);
        height: 32px;
        font-weight: 450;
        outline: none;
        background-color: transparent;
      }
      button {
        width: 90px;
        height: 32px;
        background: none;
        border: none;
        background-color: #5341c4;
        border-radius: 4px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .confirmed-passess {
      border-radius: 4px;
      background-color: #ffffff;
      border: 1px solid #e1d8f2;
      padding: 1.5rem 0.5rem;
      @media (min-width: 1200px) {
        padding: 1.5rem;
      }
      span {
        display: block;
        font-weight: normal;
      }
      .pass-details {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1.5px solid #f2f2f2;
        width: 98%;
        @media (min-width: 1200px) {
          width: 90%;
        }
        img {
          &.item-icon {
            width: 44px;
            height: 44px;
            margin-right: 8px;
            @media (min-width: 1200px) {
              width: 64px;
              height: 64px;
              margin-right: 14px;
            }
          }
          &.data-icon {
            height: 16px;
          }
        }
        .pass-type {
          color: #555063;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
        }
        .user {
          color: #6d686f;
          font-size: 16px;
          line-height: 32px;
          font-weight: 500;
          margin-bottom: 6px;
        }
        .address {
          margin-bottom: 4px;
          span {
            color: #928e94;
            line-height: 28px;
            font-size: 14px;
            font-weight: 450;
            line-height: 18px;
          }
          img {
            margin-right: 4px;
          }
        }
        .time-access {
          span {
            line-height: 28px;
            margin-right: 12px;
          }
          img.data-icon {
            margin-right: 4px;
          }
          .status {
            padding: 4px 12px;
            color: #505963;
            font-size: 10px;
            font-weight: 600;
            border-radius: 2px;
            text-transform: uppercase;
            line-height: 12px;
            &.granted {
              background-color: #a9dfbf;
            }
            &.declined {
              background-color: #ffe2e1;
            }
          }
        }
      }
    }
  }
}
