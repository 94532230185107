.zones-container {
  width: 100%;
  min-height: 98vh;
  padding-top: 24px;
  &.zone-details {
    padding: 1.5rem 4%;
    padding-top: 0;
    background-color: #f7f7ff;
    .upper {
      background-color: #fff;
      width: calc(100% + 8%);
      margin-left: -4%;
      margin-right: -4%;
      padding: 24px 4%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &.add-zone {
    padding: 1.5rem;
    padding-top: 0;
    .upper {
      background-color: #fff;
      margin-left: -4%;
      margin-right: -4%;
      padding: 24px 4%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h2 {
        color: #000000;
      }
    }
    .zone-form {
      width: 60%;
      max-width: 580px;
      h2 {
        margin: 32px 0 !important;
        color: #505163;
      }
      .form-group {
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 24px;
        input.input-field {
          display: block;
          border: none;
          padding: 10px;
          padding-left: 0;
          margin-bottom: 24px;
          border-bottom: 1px solid #adb8c7;
          width: 100%;
          max-width: 300px;
          color: #111111;
          outline: none;
          transition: all 0.2s ease-in;
          font-size: 16px;
          &::placeholder {
            color: #505963;
            &:active,
            &:after,
            &:focus,
            &:visited {
              outline: none;
              border-bottom: 1px solid #5341c5;
            }
          }
        }
      }
      .submit-changes {
        display: flex;
        justify-content: flex-end;
        padding-right: 10%;
      }
    }
  }
  .zones-overview-data {
    h1,
    h2,
    h3 {
      margin-top: unset;
      margin-bottom: unset;
    }
    padding: 0 24px 32px;
    border-bottom: 1px solid #f6f5fa;
    .menu-items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
    }
  }
  .zonedata {
    background-color: #ffffff;
    margin: 24px 0;
    padding: 24px;
    margin-bottom: 32px;
    h2 {
      font-size: 20px;
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 0 20px;
      margin-bottom: 24px;
    }
    .desc {
      padding: 28px 0;
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 16px;
      &-label {
        font-size: 14px;
        color: #828282;
        display: block;
      }
      &-desc {
        width: 80%;
        margin-top: 8px;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
    }
    .overview {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 24px;
      .zone-data {
        padding: 12px 3%;
        border-right: 1px solid #e0e0e0;
        &:first-child {
          padding-left: 0;
        }
        span {
          display: block;
          &.z-label {
            color: #828282;
            font-size: 14px;
            margin-bottom: 2px;
          }
          &.z-value {
            font-size: 16px;
            color: #333333;
          }
        }
      }
    }
  }
  .history {
    background-color: #ffffff;
    margin: 24px 0;
    padding: 24px;
    margin-bottom: 32px;
    h2 {
      font-size: 20px;
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 0 20px;
      margin-bottom: 24px;
    }
  }
  button {
    border: none;
    background: none;
    display: block;
    padding: 8px 16px;
    border-radius: 3px;
    background-color: #5341c4;
    font-weight: 600;
    color: #fff;
    font-size: 12px;
    transition: all 0.3s;
    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: #6c5bdd !important;
      border: none;
    }
    &.button-secondary {
      background-color: #f6f5fa;
      color: #505163;
      &:hover {
        background-color: #dddce2 !important;
      }
    }
  }
  .residents-table {
    width: 100%;
    padding: 2%;
    @media (min-width: 1200px) {
      padding: 3%;
      width: 80%;
      max-width: 900px;
    }
    table {
      font-size: 14px;
      font-weight: 500;
      thead {
        color: #000000;
        th {
          padding: 16px 0;
        }
      }
      tbody {
        color: #484848;
        tr {
          border-bottom: 1px solid #f3f3f3;
          &:hover {
            background-color: #f8f7ff;
            cursor: pointer;
          }
        }
        td {
          padding: 22px 0;
        }
      }
    }
  }
  .offcanvas.offcanvas-right {
    right: 0 !important;
  }
}

.status {
  span {
    font-size: 12px;
    text-transform: uppercase;
    padding: 4px 16px;
    border-radius: 3px;
    &.active {
      background-color: #a9dfbf;
    }
    &.disabled {
      background-color: #ffe2e1;
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(220px);
  }
  100% {
    transform: translateX(0);
  }
}

.content.d-flex.flex-column.flex-column-fluid {
  padding: 0;
}

.backtozones {
  margin: 24px 0;
}